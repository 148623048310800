<template>
  <div class="container">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h4>Users</h4>
          </div>

          <div class="col-auto ms-auto">
            <form @submit.prevent>
              <input
                type="text"
                v-model="search"
                class="form-control"
                placeholder="Search users"
                @input="searchUsers"
              />
            </form>
          </div>
          <div class="col-auto">
            <div class="dropdown">
              <button
                class="btn btn-outline-success dropdown-toggle"
                type="button"
                id="userRoleFilterDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Filter by
                <span class="text-capitalize">{{
                  selectedRoleFilter || "role"
                }}</span>
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="userRoleFilterDropdown"
              >
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="selectedRoleFilter = ''"
                    >All</a
                  >
                  <a
                    class="dropdown-item text-capitalize"
                    href="#"
                    v-for="r in roles"
                    :key="r.id"
                    @click.prevent="selectedRoleFilter = r.name"
                    >{{ r.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="mb-4" />

        <!--  -->
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Permissions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u in filteredUsers" :key="u.id">
              <th @click="viewUser(u)" scope="row">{{ u.id }}</th>
              <td @click="viewUser(u)">{{ u.name }}</td>
              <td @click="viewUser(u)">{{ u.email }}</td>
              <td @click="viewUser(u)" class="text-capitalize">
                {{ u.user_roles[0] }}
              </td>
              <td @click="viewUser(u)">
                <ul>
                  <li v-for="p in u.user_permissions" :key="u.id + '-' + p">
                    {{ p }}
                  </li>
                </ul>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-outline-primary"
                  :disabled="$store.impersonating"
                  @click="impersonate(u.id)"
                >
                  <i class="far fa-user-secret me-2"></i>Impersonate
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      search: "",
      roles: [],
      selectedRoleFilter: "",
    };
  },
  computed: {
    filteredUsers() {
      if (this.selectedRoleFilter) {
        return this.users.filter((u) => {
          return u.user_roles[0] === this.selectedRoleFilter;
        });
      } else {
        return this.users;
      }
    },
  },
  methods: {
    viewUser(user) {
      this.$router.push("/users/" + user.id);
    },
    searchUsers() {
      if (this.search.length > 2) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/users/search", {
            search: this.search,
          })
          .then(({ data }) => {
            this.users = data;
          });
      }

      if (this.search == "") {
        this.fetchUsers();
      }
    },
    fetchUsers() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/users")
        .then(({ data }) => {
          this.users = data;
        });
    },
    fetchRoles() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/roles")
        .then(({ data }) => {
          this.roles = data;
        });
    },
    impersonate(userId) {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/users/impersonate/" + userId)
        .then(({ data }) => {
          this.$store.impersonating = this.$store.user;
          this.$store.impersonationToken = this.$store.token;

          var storeUsers = {
            user: data.user,
            token: data.token,
            impersonating: this.$store.user,
            impersonationToken: this.$store.token,
          };

          this.$store.user = data.user;
          this.$store.token = data.token;

          localStorage.setItem("user", JSON.stringify(storeUsers));
          this.$axios.defaults.headers.Authorization = `Bearer ${this.$store.token.access_token}`;

          this.$axios.defaults.headers.common["amchara-impersonation"] = [
            this.$store.impersonating.id,
            this.$store.user.id,
          ];

          this.$router.push("/dashboard");
        });
    },
  },
  created() {
    this.fetchRoles();
    this.fetchUsers();
  },
};
</script>

<style>
</style>